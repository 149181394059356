import React from 'react'
import Layout from '../../components/Layout'
import Head from '../../components/Head'
import SEOAboutPage from '../../components/SEO/aboutPage'
import MapLocation from '../../components/mapLocation'
import { useStaticQuery, graphql } from "gatsby"

export default function About() {

  const pageData = useStaticQuery(graphql`
    query AboutQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      graphCmsAboutPage {
        id
        pageHeaderSeo {
          metaDescription
          metaTitle
        }
        slug
        title
        content {
          html
        }
      }
    }
  `)

  const pageUrl = pageData.site.siteMetadata.siteUrl + "/" + pageData.graphCmsAboutPage.slug + "/";


  return (
    <Layout>
      <Head
        title={pageData.graphCmsAboutPage.pageHeaderSeo.metaTitle}
        pageDesc={pageData.graphCmsAboutPage.pageHeaderSeo.metaDescription}
        url={pageUrl}
      />
      <SEOAboutPage
        title={pageData.graphCmsAboutPage.pageHeaderSeo.metaTitle}
        meta_description={pageData.graphCmsAboutPage.pageHeaderSeo.metaDescription}
        url={pageUrl}
      />
      <div className="pageSection"
        dangerouslySetInnerHTML={{ __html: pageData.graphCmsAboutPage.content.html }}
      >
      </div>
      <MapLocation />
    </Layout>
  )
}
